import { useState, useEffect } from "react";
import { dataBase } from "../firebaseConnection";
import { doc, getDoc } from "firebase/firestore";
import { useParams, Link } from "react-router-dom";
import { BiBed, BiBath, BiArea, BiPhoneCall, BiMailSend } from "react-icons/bi";
import Carousel from "../components/Carousel";
import GoogleMap from "../components/GoogleMap";

function formatNumberWithDots(number) {
  if (number !== undefined) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } 
}


const PropertyDetails = () => {
  const { id } = useParams();
  const [houseData, setHouseData] = useState({});

  useEffect(() => {
    async function fetchData() {
      const data = await getPropertyData(id);
      setHouseData(data);
    }
    fetchData();
  }, [id]);
  async function getPropertyData(id) {
    const propertyRef = doc(dataBase, "properties", id);
    const propertySnapshot = await getDoc(propertyRef);
    if (propertySnapshot.exists()) {
      return propertySnapshot.data();
    } else {
      console.log(`No property found with ID ${id}`);
      return null;
    }
  }

  return (
    <section>
      {window.scrollTo(0,0)}
      <div className="container mx-auto min-h-[800px] mb-14">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
          <div>
            <h2 className="text-2xl font-semibold text-white">
              {houseData.propertyName}
            </h2>
            <h3 className="text-lg mb-4 text-white">
              {houseData.propertyAdress}
            </h3>
          </div>
          <div className="mb-4 lg:mb-0 flex gap-x-2 text-sm">
            <div className="bg-yellow-500 text-white rounded-full px-3">
              {houseData.propertyType}
            </div>
            <div className="bg-blue-800 text-white rounded-full px-3">
              {houseData.propertyNeighborhood}
            </div>
          </div>
          <div className="text-3xl font-semibold text-white">
            {formatNumberWithDots(houseData.propertyPrice)} R$
          </div>
        </div>
        <div className="flex flex-col items-start gap-8 lg:flex-row">
          <div className="max-w-[768px] lg:w-[768px]">
            <div className="mb-8">
              {houseData.carouselPictures ? (
                <Carousel images={houseData.carouselPictures} />
              ) : null}
            </div>
            <div className="flex gap-x-6 text-blue-500 mb-6">
              <div className="flex gap-x-2 items-center">
                <BiBed className="text-2xl" />
                <div>{houseData.propertyBedrooms}</div>
              </div>
              <div className="flex gap-x-2 items-center">
                <BiBath className="text-2xl" />
                <div>{houseData.propertyBathrooms}</div>
              </div>
              <div className="flex gap-x-2 items-center">
                <BiArea className="text-2xl" />
                <div>{houseData.propertySurface}</div>
              </div>
            </div>
            <div className="text-white">{houseData.propertyDescription}</div>
            <div className="mt-10 hidden md:block">
              <div className="flex flex-row justify-between items-center">
                <h1 className="text-xl font-semibold text-white mr-0 lg:mr-6">
                  Localização
                </h1>
                <p className="font-medium text-white">
                  <span>Endereço: </span>
                  {houseData.propertyAdress}
                </p>
              </div>
              <GoogleMap
                latitude={houseData.propertyLatitude}
                longitude={houseData.propertyLongitude}
              />
            </div>
          </div>
          <div className="flex-1 bg-white w-full mb-8 border border-gray-300 rounded-lg px-6 py-8">
            <div className="flex items-center gap-x-4 mb-8">
              <div className="w-20 h-20 p-1 border border-gray-300 rounded-full">
                <img
                  className="rounded-full w-full h-full object-cover"
                  src={houseData.agentPicture}
                  alt=""
                />
              </div>
              <div className="font-bold text-lg">
                <div>{houseData.agentName}</div>
                <Link to="/" className="text-blue-800 text-sm">
                  Visualizar Listagem
                </Link>
              </div>
            </div>
            <div className="flex items-center gap-x-1">
              <BiPhoneCall className="text-2xl text-blue-800" />
              <h3 className="sm:text-[15px] text-[12px] whitespace-nowrap">
                (81) 99990-6772
              </h3>
              <BiMailSend className="text-2xl text-blue-800" />
              <h3 className="sm:text-[15px] text-[12px]">
                glaucooventura@gmail.com
              </h3>
            </div>
            <div className="flex gap-x-2 mt-4">
              <button
                onClick={() => {
                  window.open("https://wa.me/5581999906772", "_blank");
                }}
                className="bg-blue-700 hover:bg-blue-800 text-white rounded p-4 text-sm w-full transition"
              >
                WhatsApp
              </button>
              <button
                onClick={() => {
                  window.open("mailto:glaucooventura@gmail.com", "_blank");
                }}
                className="border border-blue-600 text-blue-600 hover:border-blue-500  rounded p-4 text-sm w-full transition"
              >
                Email
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropertyDetails;
