import React from "react";
import capa01 from "../assets/img/capa01.png";
import Search from "../components/Search";

const Banner = () => {
  return (
    <section className="h-full max-h-[640px] mb-8 xl:mb-24">
      <div className="hidden flex-1 lg:flex justify-center">
        <img src={capa01} alt="Banner" className="h-[550px] mb-20" />
      </div> 
      <div className=" lg:ml-8 xl:ml-[135px] flex flex-col items-center lg:hidden lg:items-start text-center lg:text-left justify-center flex-1 px-4 lg:px-0">
          <h1 className=" text-4xl lg:text-[58px] font-semibold lg:hidden leading-none mb-6 lg:ml-24 text-white">
            <span className="text-yellow-500">Encontre</span> o lar dos seus
            sonhos conosco!
          </h1>
          <p className="max-w-[480px] mb-8 ml-0 lg:ml-24 text-white">
            Nossa equipe está comprometida em fornecer o melhor serviço e tornar
            sua busca pela casa dos sonhos fácil e sem estresse. Deixe-nos
            ajudá-lo a encontrar o lar perfeito para você e sua família. Entre
            em contato hoje mesmo!
          </p>
        </div>
      <Search />
    </section>
  );
};

export default Banner;
