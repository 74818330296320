import React, { useState, useContext } from "react";

import {
  RiWallet3Line,
  RiArrowDownSLine,
  RiArrowUpSLine,
} from "react-icons/ri";

import { Menu } from "@headlessui/react";

import { HouseContext } from "./HouseContext";

const PriceRangeDropdown = () => {
  const { propertyPrice, setPropertyPrice } = useContext(HouseContext);
  const [isOpen, setIsOpen] = useState(false);

  const prices = [
    {
      value: "Todos os valores",
    },
    {
      value: "100000 - 150000 R$",
    },
    {
      value: "150000 - 250000 R$",
    },
    {
      value: "250000 - 350000 R$",
    },
    {
      value: "350000 - 450000 R$",
    },
    {
      value: "450000 - 750000 R$",
    },
    {
      value: "750000 - 5000000 R$",
    },
  ];

  return (
    <Menu as="div" className="dropdown relative">
      <Menu.Button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-white dropdown-btn w-full text-left mr-10"
      >
        <RiWallet3Line className="dropdown-icon-primary" />
        <div>
          <div className="text-[15px] font-medium leading-tight">
            {propertyPrice}
          </div>
          <div className="text-[10px]">Faixa de preço</div>
        </div>
        {isOpen ? (
          <RiArrowUpSLine className="dropdown-icon-secondary" />
        ) : (
          <RiArrowDownSLine className="dropdown-icon-secondary" />
        )}
      </Menu.Button>
      <Menu.Items className="dropdown-menu max-w-xs">
        {prices.map((price, index) => {
          return (
            <Menu.Item
              onClick={() => setPropertyPrice(price.value)}
              className="cursor-pointer hover:text-blue-800 transition"
              as="li"
              key={index}
            >
              {price.value}
            </Menu.Item>
          );
        })}
      </Menu.Items>
    </Menu>
  );
};

export default PriceRangeDropdown;
