import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";

const Carousel = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    setCurrentSlide(0);
  }, [images]);

  const previousSlide = () => {
    setCurrentSlide(currentSlide === 0 ? images.length - 1 : currentSlide - 1);
  };

  const nextSlide = () => {
    setCurrentSlide(currentSlide === images.length - 1 ? 0 : currentSlide + 1);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 37) {
      // left arrow
      previousSlide();
    } else if (event.keyCode === 39) {
      // right arrow
      nextSlide();
    }
  };

  return (
    <div className="relative h-96" onKeyDown={handleKeyDown} tabIndex="0">
      <div className="flex items-center justify-center h-full">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={"Carousel de imagens"}
            className={`absolute w-full h-full object-contain transition-opacity duration-1000 ${
              index === currentSlide ? "opacity-100" : "opacity-0"
            }`}
          />
        ))}
      </div>
      <button
        className="absolute top-1/2 left-0 transform -translate-y-1/2 px-4 py-2 rounded-lg bg-white bg-opacity-50 hover:bg-opacity-75 ml-1"
        aria-label="Previous slide"
        onClick={previousSlide}
      >
        <RiArrowLeftSLine size={18} />
      </button>
      <button
        className="absolute top-1/2 right-0 transform -translate-y-1/2 px-4 py-2 rounded-lg bg-white bg-opacity-50 hover:bg-opacity-75 mr-1"
        aria-label="Next slide"
        onClick={nextSlide}
      >
        <RiArrowRightSLine size={18} />
      </button>
    </div>
  );
};

Carousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Carousel;
