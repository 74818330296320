import React, { useState, useContext } from "react";

import { RiMapPinLine, RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

import { Menu } from "@headlessui/react";

import { HouseContext } from "./HouseContext";
const NeighborhoodDropdown = () => {
  const {
    propertyNeighborhoods,
    setPropertyNeighborhoods,
    uniqueNeighborhoods,
  } = useContext(HouseContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Menu as="div" className="dropdown relative">
      <Menu.Button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-white dropdown-btn w-full text-left mr-10"
      >
        <RiMapPinLine className="dropdown-icon-primary" />
        <div>
          <div className="text-[15px] font-medium leading-tight">
            {propertyNeighborhoods}
          </div>
          <div className="text-[10px] ">Selecione uma localização</div>
        </div>
        {isOpen ? (
          <RiArrowUpSLine className="dropdown-icon-secondary" />
        ) : (
          <RiArrowDownSLine className="dropdown-icon-secondary" />
        )}
      </Menu.Button>
      <Menu.Items className="dropdown-menu max-w-xs">
        {uniqueNeighborhoods.map((neighborhood, index) => {
          return (
            <Menu.Item
              onClick={() => setPropertyNeighborhoods(neighborhood)}
              className="cursor-pointer hover:text-blue-800 transition"
              as="li"
              key={index}
            >
              {neighborhood}
            </Menu.Item>
          );
        })}
      </Menu.Items>
    </Menu>
  );
};

export default NeighborhoodDropdown;
