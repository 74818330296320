import React from "react";

import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.png";

const Header = () => {
  return (
    <header className="py-6 mb-12">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/">
          <div className="flex items-center">
            <img
              src={Logo}
              className="h-[25px] w-[25px] "
              alt="Ventura Corretoria"
            />
            <h1 className="ml-5 font-light uppercase text-gray-400">
              Soluções Imobiliárias
            </h1>
          </div>
        </Link>
      </div>
    </header>
  );
};

export default Header;
