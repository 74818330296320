import { useState } from "react";
import { auth, dataBase } from "../firebaseConnection";
import { FaUpload } from "react-icons/fa";
import { signOut } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import { ImSpinner2 } from "react-icons/im";

const ManageProperties = () => {
  const [carouselFileToBeUploaded, setCarouselFileToBeUploaded] =
    useState(null);
  const [mainFileToBeUploaded, setMainFileToBeUploaded] = useState(null);
  const [agentFileToBeUploaded, setAgentFileToBeUploaded] = useState(null);
  const [newPropertyName, setNewPropertyName] = useState("");
  const [newPropertyType, setNewPropertyType] = useState("");
  const [newPropertyDescription, setNewPropertyDescription] = useState("");
  const [newPropertyNeighborhood, setNewPropertyNeighborhood] = useState("");
  const [newPropertyAddress, setNewPropertyAddress] = useState("");
  const [newPropertyLatitude, setNewPropertyLatitude] = useState("");
  const [newPropertyLongitude, setNewPropertyLongitude] = useState("");
  const [newPropertyBathrooms, setNewPropertyBathrooms] = useState("");
  const [newPropertySuites, setNewPropertySuites] = useState("");
  const [newPropertyBedrooms, setNewPropertyBedrooms] = useState("");
  const [newPropertySurface, setNewPropertySurface] = useState("");
  const [newPropertyPrice, setNewPropertyPrice] = useState(0);
  const [newAgentName, setNewAgentName] = useState("");
  const [newAgentNumber, setNewAgentNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const storage = getStorage();

  const handleLogout = async () => {
    await signOut(auth);
  };

  const uploadCarouselFiles = async (files) => {
    if (!files || files.length === 0) return [];

    const urls = [];

    for (const file of files) {
      const filesFolderRef = ref(storage, `properties/${file.name}`);

      try {
        await uploadBytes(filesFolderRef, file);
        const url = await getDownloadURL(filesFolderRef);
        urls.push(url);
      } catch (error) {
        console.error(error);
      }
    }

    return urls;
  };

  const uploadMainFile = async () => {
    if (!mainFileToBeUploaded) return;
    const filesFolderRef = ref(
      storage,
      `properties/${mainFileToBeUploaded.name}`
    );
    try {
      await uploadBytes(filesFolderRef, mainFileToBeUploaded);
      const url = await getDownloadURL(filesFolderRef);
      return url;
    } catch (error) {
      console.error(error);
    }
  };

  const uploadAgentFile = async () => {
    if (!agentFileToBeUploaded) return;
    const filesFolderRef = ref(
      storage,
      `properties/${agentFileToBeUploaded.name}`
    );
    try {
      await uploadBytes(filesFolderRef, agentFileToBeUploaded);
      const url = await getDownloadURL(filesFolderRef);
      return url;
    } catch (error) {
      console.error(error);
    }
  };

  const LoadingScreen = () => {
    return (
      <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
        <ImSpinner2 className="animate-spin text-blue-800 text-4xl" />
      </div>
    );
  };

  const handleSubmitProperty = async (e) => {
    e.preventDefault();

    if (
      !newPropertyName ||
      !newPropertyType ||
      !newPropertyDescription ||
      !newPropertyNeighborhood ||
      !newPropertyAddress ||
      !newPropertyLatitude ||
      !newPropertyLongitude ||
      !newPropertyBathrooms ||
      !newPropertySuites ||
      !newPropertyBedrooms ||
      !newPropertySurface ||
      !newPropertyPrice ||
      !newAgentName ||
      !newAgentNumber ||
      !mainFileToBeUploaded ||
      !agentFileToBeUploaded ||
      !carouselFileToBeUploaded
    ) {
      alert("Verifique se não há nenhum campo inválido...");
      return;
    }
    setLoading(true);
    // Call the image upload functions
    const mainPictureUrl = await uploadMainFile();
    const carouselPicturesUrl = await uploadCarouselFiles(
      carouselFileToBeUploaded
    );
    const agentPictureUrl = await uploadAgentFile();

    await addDoc(collection(dataBase, "properties"), {
      userId: auth?.currentUser?.uid,
      propertyName: newPropertyName,
      propertyType: newPropertyType,
      propertyDescription: newPropertyDescription,
      propertyNeighborhood: newPropertyNeighborhood,
      propertyAdress: newPropertyAddress,
      propertyLatitude: newPropertyLatitude,
      propertyLongitude: newPropertyLongitude,
      propertyBathrooms: newPropertyBathrooms,
      propertySuites: newPropertySuites,
      propertyBedrooms: newPropertyBedrooms,
      propertySurface: newPropertySurface,
      propertyPrice: newPropertyPrice,
      agentName: newAgentName,
      agentNumber: newAgentNumber,
      mainPicture: mainPictureUrl,
      carouselPictures: carouselPicturesUrl,
      agentPicture: agentPictureUrl,
    })
      .then(() => {
        alert("Propriedade registrada com sucesso.");
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="min-h-[1800px]">
      {loading && <LoadingScreen />}
      <div className="absolute top-[0.7%] right-[2%]">
        <button
          onClick={handleLogout}
          className="text-white bg-yellow-500 rounded-3xl p-2 hover:bg-yellow-600"
        >
          Logout
        </button>
      </div>
      <form className="w-full max-w-lg mx-auto" onSubmit={handleSubmitProperty}>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">
            Nome da propriedade
          </label>
          <input
            type="text"
            onChange={(e) => setNewPropertyName(e.target.value)}
            placeholder="Insira o nome da propriedade"
            className="w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-lg shadow-sm focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">
            Tipo de Imóvel
          </label>
          <input
            type="text"
            onChange={(e) => setNewPropertyType(e.target.value)}
            placeholder="Insira o tipo de propriedade"
            className="w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-lg shadow-sm focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">Descrição</label>
          <textarea
            rows="6"
            onChange={(e) => setNewPropertyDescription(e.target.value)}
            className="w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-lg shadow-sm focus:outline-none"
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">
            Enviar Imagem Principal:
          </label>
          <button
            onClick={uploadMainFile}
            className="flex justify-between text-sm items-center w-full text-white bg-blue-500 rounded-lg p-2 focus:outline-none"
          >
            Selecione uma imagem <FaUpload />
          </button>
          <input
            type="file"
            onChange={(e) => setMainFileToBeUploaded(e.target.files[0])}
            id="imagemPrincipal"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">
            Enviar Imagens para o Anúncio:
          </label>
          <button
            onClick={uploadCarouselFiles}
            className="flex justify-between text-sm items-center w-full text-white bg-blue-500 rounded-lg p-2 focus:outline-none"
          >
            Selecione imagens <FaUpload />
          </button>
          <input
            type="file"
            multiple
            onChange={(e) => setCarouselFileToBeUploaded(e.target.files)}
            id="imagemPrincipal"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">Bairro</label>
          <input
            type="text"
            onChange={(e) => setNewPropertyNeighborhood(e.target.value)}
            placeholder="Insira o bairro da propriedade"
            className="w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-lg shadow-sm focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">Endereço</label>
          <input
            type="text"
            placeholder="Insira o endereço da propriedade"
            onChange={(e) => setNewPropertyAddress(e.target.value)}
            className="w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-lg shadow-sm focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">Latitude</label>
          <input
            type="text"
            onChange={(e) => setNewPropertyLatitude(e.target.value)}
            placeholder="Insira a latitude da propriedade (veja no google maps)"
            className="w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-lg shadow-sm focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">Longitude</label>
          <input
            type="text"
            onChange={(e) => setNewPropertyLongitude(e.target.value)}
            placeholder="Insira a longitude da propriedade (veja no google maps)"
            className="w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-lg shadow-sm focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">
            Selecione o número de banheiros:
          </label>
          <select
            onChange={(e) => setNewPropertyBathrooms(e.target.value)}
            className="w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-lg shadow-sm focus:outline-none"
          >
            <option value="0">Nenhum banheiro</option>
            {[...Array(10)].map((_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">
            Selecione o número de suítes:
          </label>
          <select
            onChange={(e) => setNewPropertySuites(e.target.value)}
            className="w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-lg shadow-sm focus:outline-none"
          >
            <option value="0">Nenhuma suíte</option>
            {[...Array(10)].map((_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">
            Selecione o número de quartos:
          </label>
          <select
            onChange={(e) => setNewPropertyBedrooms(e.target.value)}
            className="w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-lg shadow-sm focus:outline-none"
          >
            <option value="0">Nenhum quarto</option>
            {[...Array(10)].map((_, index) => (
              <option key={index + 1} value={index + 1}>
                {index + 1}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">
            Área da propriedade
          </label>
          <input
            type="text"
            onChange={(e) => setNewPropertySurface(e.target.value)}
            placeholder="Insira a área da propriedade (em m²)"
            className="w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-lg shadow-sm focus:outline-none"
          />
        </div>
        <div className="mb-10">
          <label className="block mb-2 font-bold text-white">
            Valor da propriedade
          </label>
          <input
            type="number"
            onChange={(e) => setNewPropertyPrice(e.target.value)}
            placeholder="Insira o valor da propriedade"
            className="w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-lg shadow-sm focus:outline-none"
          />
        </div>
        <h1 className="flex justify-center items-center text-white text-xl font-semibold mb-8">
          Dados do Corretor
        </h1>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">
            Foto do corretor:
          </label>
          <button
            onClick={uploadAgentFile}
            className="flex justify-between text-sm items-center w-full text-white bg-blue-500 rounded-lg p-2 focus:outline-none"
          >
            Selecione uma imagem <FaUpload />
          </button>
          <input
            type="file"
            onChange={(e) => setAgentFileToBeUploaded(e.target.files[0])}
            id="imagemPrincipal"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">
            Nome do corretor
          </label>
          <input
            type="text"
            onChange={(e) => setNewAgentName(e.target.value)}
            placeholder="Insira o nome do corretor"
            className="w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-lg shadow-sm focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold text-white">
            Número do corretor
          </label>
          <input
            type="text"
            onChange={(e) => setNewAgentNumber(e.target.value)}
            placeholder="Insira o número do corretor"
            className="w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-lg shadow-sm focus:outline-none"
          />
        </div>
        <div className="flex justify-center items-center">
          <button
            type="submit"
            className="bg-yellow-500 w-full rounded-xl py-2 px-1 text-white"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  );
};

export default ManageProperties;
