import React from "react";
import { BiPhoneCall, BiMailSend } from "react-icons/bi";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-main py-8 px-6 border-t">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="flex flex-col mb-4 lg:mb-0">
            <h4 className="mb-4 text-lg font-medium text-white">Links úteis</h4>
            <ul className="list-none">
              <li className="mb-2">
                <Link to="/" className="text-white">
                  Home
                </Link>
              </li>
              <li className="mb-2 text-white">
                <a
                  href="https://www.instagram.com/glaucoventura/"
                  target="_blank"
                >
                  Instagram
                </a>
              </li>
              <li className="mb-2 text-white">
                <a href="https://wa.me/558199906772" target="_blank">
                  WhatsApp
                </a>
              </li>
            </ul>
          </div>
          <div className="flex flex-col mb-4 lg:mb-0">
            <h4 className="mb-4 text-lg font-medium text-white">Localização</h4>
            <p className="text-sm text-white">Rua Árnobio Marques, 253</p>
            <p className="text-sm text-white">Santo Amaro, Recife/PE</p>
            <p className="text-sm font-semibold text-white">Sala 1907</p>
          </div>
          <div className="flex flex-col">
            <h4 className="mb-4 text-lg font-medium text-white">Contato</h4>
            <div className="flex items-center mb-2">
              <BiPhoneCall className="text-blue-500 mr-2" />
              <a className="text-white" href="tel:(81) 99999-9999">
                (81) 99990-6772
              </a>
            </div>
            <div className="flex items-center mb-2">
              <BiMailSend className="text-blue-500 mr-2" />
              <a className="text-white" href="mailto:contato@imoveis.com.br">
                glaucooventura@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 pt-4 text-sm text-center text-white">
        &copy; 2023 GV Soluções Imobiliárias. Todos os direitos reservados.
      </div>
    </footer>
  );
};

export default Footer;
