import React from "react";

import { BiBed, BiBath, BiArea } from "react-icons/bi";

const House = ({ house }) => {
  const {
    mainPicture: image,
    propertyType: type,
    propertyNeighborhood: neighborhood,
    propertyName: name,
    propertyBedrooms: bedrooms,
    propertyBathrooms: bathrooms,
    propertySurface: surface,
    propertyPrice: price,
  } = house;

  function formatNumberWithDots(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return (
    <div className="bg-white shadow-1 p-5 rounded-lg w-full max-w-[352px] h-[600px] mx-auto cursor-pointer hover:shadow-2xl transition">
      <img
        className="mb-8 w-[312px] h-[312px] object-cover"
        src={image}
        alt="Houses"
      />
      <div className="mb-4 flex gap-x-2 text-sm ">
        <div className="bg-yellow-500 rounded-full text-white px-3">{type}</div>
        <div className=" bg-main rounded-full text-white px-3">
          {neighborhood}
        </div>
      </div>
      <div className="text-lg font-semibold max-w-[260px] h-[64px]">{name}</div>
      <div className="flex gap-x-4 my-4 ">
        <div className="flex items-center text-gray-600 gap-1">
          <div className="text-[20px]">
            <BiBed />
          </div>
          <div>{bedrooms}</div>
        </div>
        <div className="flex items-center text-gray-600 gap-1">
          <div className="text-[20px]">
            <BiBath />
          </div>
          <div>{bathrooms}</div>
        </div>
        <div className="flex items-center text-gray-600 gap-1">
          <div className="text-[20px]">
            <BiArea />
          </div>
          <div>{surface}</div>
        </div>
      </div>

      <div className="text-lg font-semibold text-blue-700 mb-4 ">
        {formatNumberWithDots(price)} R$
      </div>
    </div>
  );
};

export default House;
