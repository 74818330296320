import { useContext } from "react";
import { auth, dataBase } from "../firebaseConnection";
import { signOut } from "firebase/auth";
import { deleteDoc, doc, getDoc } from "firebase/firestore";
import { getStorage, deleteObject, ref } from "firebase/storage";
import { HouseContext } from "../components/HouseContext";
import { Link } from "react-router-dom";

import House from "../components/House";

const handleLogout = async () => {
  await signOut(auth);
};

async function deleteProperty(id) {
  const docRef = doc(dataBase, "properties", id);
  const docSnap = await getDoc(docRef);
  const property = docSnap.data();

  // Verificar se os campos de fotos estão definidos corretamente
  if (!property?.agentPicture || !property?.carouselPictures || !property?.mainPicture) {
    console.error("Os campos de fotos não estão definidos corretamente no documento");
    return;
  }

  // Obter todas as URLs de fotos
  const photoUrls = [property.agentPicture, ...property.carouselPictures, property.mainPicture];

  // Excluir as fotos do storage
  const storage = getStorage();
  const promises = photoUrls.map((photoUrl) =>
    deleteObject(ref(storage, photoUrl))
  );
  await Promise.all(promises);

  // Excluir o documento da coleção
  await deleteDoc(docRef);
}

const Admin = () => {
  const { allProperties } = useContext(HouseContext);

  return (
    <div className="min-h-[1800px]">
        {window.scrollTo(0,0)}
      <div className="absolute top-[0.7%] right-[2%]">
        <Link
          to={"/admin/newproperty"}
          className="text-white bg-yellow-500 rounded-3xl p-2 hover:bg-yellow-600"
        >
          Adicionar Imóveis
        </Link>
      </div>
      <h1 className="text-white text-4xl font-semibold flex justify-center items-center mb-8">
        Lista de Propriedades
      </h1>
      <div className="flex justify-center items-center flex-col">
        <div className="container mx-auto">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-14">
            {allProperties.map((house) => {
              return (
                <div key={house.id}>
                  <House house={house} />
                  <button
                    onClick={() => deleteProperty(house.id)}
                    className="bg-yellow-500 rounded-2xl p-2 hover:bg-yellow-600 text-white mt-2 flex justify-center items-center mx-auto"
                  >
                    Apagar Anúncio
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <button
          onClick={handleLogout}
          className="text-white bg-yellow-500 rounded-3xl p-2 fixed bottom-[5%] right-[2%] "
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Admin;
