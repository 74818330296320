import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const Map = ({ latitude, longitude }) => {
  const mapContainerStyle = {
    margin: "20px 0 0",
    height: "50vh",
    width: "100%",
  };

  const center = {
    lat: Number(latitude),
    lng: Number(longitude),
  };

  return (
    <div className="h-max w-full">
      <LoadScript googleMapsApiKey="AIzaSyBaS1ALbRroFr-iCLV7jDnOXE7rX2TgbLY">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={15}
        >
          <Marker position={center} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default Map;
