import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-5xl font-bold text-red-700 mb-4">Oops!</h1>
      <h2 className="text-2xl font-medium text-white mb-8">
        Não encontramos a página que você está procurando.
      </h2>
      <p className="text-lg text-white mb-8">
        A página que você está tentando acessar pode ter sido removida, ter o
        nome alterado ou estar temporariamente indisponível.
      </p>
      <Link
        to="/"
        className="px-6 py-3 bg-blue-500 hover:bg-blue-600 text-white font-medium rounded-md"
      >
        Voltar para a página inicial
      </Link>
    </div>
  );
};

export default NotFound;
