import { Menu } from "@headlessui/react";
import { GoLaw } from "react-icons/go";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";

const LegalServicesDropdown = () => {
  return (
    <Menu as="div" className="dropdown relative">
      {({ open }) => (
        <>
          <Menu.Button className="bg-white dropdown-btn w-full text-left mr-10">
            <GoLaw className="dropdown-icon-primary" />
            <div>
              <div className="text-[15px] font-medium leading-tight">
                Serviços Jurídicos
              </div>
              <div className="text-[10px]">Nossos parceiros</div>
            </div>
            {open ? (
              <RiArrowUpSLine className="dropdown-icon-secondary" />
            ) : (
              <RiArrowDownSLine className="dropdown-icon-secondary" />
            )}
          </Menu.Button>

          <Menu.Items className="dropdown-menu max-w-xs">
            <div className="font-medium hover:font-bold mb-2">
              <a
                href="https://api.whatsapp.com/send?phone=5581999434387&text=Ol%C3%A1%20Giovanna%2C%20gostaria%20de%20ter%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20servi%C3%A7os%20jur%C3%ADdicos."
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="text-[15px]">Giovanna Fischer</p>
                <p className="text-[11px]">OAB/PE: 53.716</p>
              </a>
            </div>
            <div className="font-medium hover:font-bold mb-2">
              <a
                href="https://api.whatsapp.com/send?phone=5581981186478&text=Ol%C3%A1%20M%C3%A1rcio%2C%20gostaria%20de%20ter%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20servi%C3%A7os%20jur%C3%ADdicos."
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="text-[15px]">Márcio Menezes</p>
                <p className="text-[11px]">OAB/PE: 29.976</p>
              </a>
            </div>

            {/* Direito Previdenciário Dropdown */}
            <Menu as="div" className="mb-3">
              {({ open }) => (
                <>
                  <Menu.Button className="flex justify-between w-full">
                    <p className="text-[14px]">Direito Previdenciário</p>
                    {open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </Menu.Button>
                  <Menu.Items className="text-blue-900 list-disc-edit pl-8 mt-1 text-sm">
                    <li>Aposentadoria por idade</li>
                    <li>Aposentadoria por tempo de contribuição</li>
                    <li>Auxílio-doença</li>
                  </Menu.Items>
                </>
              )}
            </Menu>

            {/* Direito Penal Dropdown */}
            <Menu as="div" className="mb-3">
              {({ open }) => (
                <>
                  <Menu.Button className="flex justify-between w-full">
                    <p className="text-[15px]">Direito Penal</p>
                    {open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </Menu.Button>
                  <Menu.Items className="text-blue-900 list-disc-edit pl-8 mt-1 text-sm">
                    <li>Crimes contra o patrimônio</li>
                    <li>Crimes contra a pessoa</li>
                    <li>Crimes contra a honra</li>
                  </Menu.Items>
                </>
              )}
            </Menu>

            {/* Direito Trabalhista Dropdown */}
            <Menu as="div" className="mb-3">
              {({ open }) => (
                <>
                  <Menu.Button className="flex justify-between w-full">
                    <p className="text-[15px]">Direito Trabalhista</p>
                    {open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </Menu.Button>
                  <Menu.Items className="text-blue-900 list-disc-edit pl-8 mt-1 text-sm">
                    <li>Rescisão de contrato</li>
                    <li>Assédio no ambiente de trabalho</li>
                    <li>Férias e 13º salário</li>
                  </Menu.Items>
                </>
              )}
            </Menu>

            {/* Direito Cível Dropdown */}
            <Menu as="div" className="mb-2">
              {({ open }) => (
                <>
                  <Menu.Button className="flex justify-between w-full">
                    <p className="text-[15px]">Direito Cível</p>
                    {open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </Menu.Button>
                  <Menu.Items className="text-blue-900 list-disc-edit pl-8 mt-1 text-sm">
                    <li className="mb-2">Direito Imobiliário</li>
                    <li className="mb-2">Responsabilidade civil</li>
                    <li>Direito das famílias</li>
                  </Menu.Items>
                </>
              )}
            </Menu>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

export default LegalServicesDropdown;
